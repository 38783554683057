<template>
  <div class="qiun-charts">
    <div id="mapChart"></div>
  </div>
</template>

<script>

import defaultData from './china.js'
import { getVenueOfFirstPage, getChinaData } from '@/api/home'
import { setLocal, getLocal } from '@/utils/utils'
import * as echarts from './echarts.min.js'
import throttle from 'lodash.throttle'
export default {
  props: {
    // 滚动条距离头部距离
    pageScrol: {
      type: Number,
      default: _ => 0
    }
  },
  data () {
    return {
      mapChart: null,

      option: null,

      // 所有省份数据
      chinaData: '',

      // 已经开放地区
      openAreasArr: [],
      // 当前循环数据索引
      curIndex: 0,

      // 场馆更换定时器
      timer: null,
      // 场馆重新启动定时器
      timeOut: null,
      tipsTitle: {},
    }
  },
  watch: {
    '$route': {
      handler: function (v, o) {
        if (v.fullPath != '/localPavilion') {
          clearInterval(this.timer)
        }
      }, immediate: true
    }
  },
  mounted () {
    // 获取所有省份数据
    this.getChinaData()


  },
  methods: {


    // 获取所有省份数据
    getChinaData () {
      this.chinaData = getLocal('chinaData');

      if (this.chinaData !== '' && this.chinaData !== null) {
        // 缓存中已存在 - 省份数据
        // 获取地方馆已开放场馆
        this.getVenueOfFirstPage();

        return
      };

      // 缓存中不存在 - 省份数据
      // 接口获取 
      getChinaData().then(res => {
        if (res.code == 10000) {
          this.chinaData = res.data;
          setLocal('chinaData', this.chinaData);

          // 获取地方馆已开放场馆
          this.getVenueOfFirstPage();
        }
      })
    },

    // 获取地方馆已开放场馆
    getVenueOfFirstPage () {
      // 已开放场馆
      this.openAreasArr = [];
      getVenueOfFirstPage().then(res => {
        if (res.code == 10000) {
          let data = res.data;
          let dataLen = data.length;
          let defaultDataLen = defaultData.length;
          // console.log('defaultDataLen', defaultDataLen, defaultData)
          if (data && data.length != 0) {

            for (let j = 0; j < dataLen; j++) {
              // 去除 ‘馆’
              data[j].venueName = data[j].venueName.split('馆')[0];
              // 判断是否存在 logo
              if (data[j].venueHeadUrl == null || data[j].venueHeadUrl == '') {
                data[j].venueHeadUrl = 'https://qdz.hsz88.com/static/logo-two.png'
              } else {
                data[j].venueHeadUrl = this.$addUrlPrefix(data[j].venueHeadUrl)
              };
              // console.log(data[j].venueHeadUrl)

              for (let i = 0; i < defaultDataLen; i++) {
                if (defaultData[i].name.indexOf(data[j].venueName) > -1) {

                  // 默认展示标识
                  defaultData[i].value = 1;
                  // 增加定位字段 - 用于 markPoint

                  defaultData[i].coord = this.chinaData.features[i].properties.centroid;

                  // 合并对象
                  Object.assign(defaultData[i], data[j]);
                  // 已开放场馆
                  this.openAreasArr.push(defaultData[i]);

                  break
                }
              }
            }
          };

          // 数据获取完毕 - 初始化地图配置
          this.initOptions()
        }
      })
    },

    // 初始化配置
    initOptions () {
      echarts.registerMap('china', this.chinaData);

      this.mapChart = echarts.init(document.getElementById('mapChart'));
      // console.log('item', this.openAreasArr[this.curIndex])
      // 初始化配置
      this.option = {
        tooltip: {
          // triggerOn: 'click',
          confine: true,
          showDelay: 0,
          transitionDuration: 0.2,
          extraCssText: 'z-index: 2;padding: 10px',
          formatter: params => {
            let data = params.data;
            if (!data || data.value == 0) {
              return
            };

            let html = `<div style="display: flex;pointer-events: all;">
                                            <div style="display: flex;flex-direction: column;">
                                                <span style="margin-top: 2px;
                                                             font-family: YouSheBiaoTiHei;
                                                             font-size: 17px;
                                                             white-space: nowrap;
                                                             color: #FFFFFF;
                                                             line-height: 18px;">${data.venueName}馆</span>
                                                <span style="min-width: 75px;
                                                             height: 18px;
                                                             margin-top: 2px;
                                                             font-size: 12px;
                                                             font-weight: 400;
                                                             color: #FFFFFF;
                                                             line-height: 18px;">${data.venueText}</span>
                                            </div>
                                            <div style="width: 40px;height: 40px;margin-left:10px">  
                                                <img style="width: 40px;height: 40px;" 
                                                       src="${data.venueHeadUrl}"/>
                                            </div>
                                         </div>`

            return html;
          },
          backgroundColor: "#704123",//提示标签背景颜色
          textStyle: {
            color: "#fff",
          }
        },
        visualMap: {
          show: false,
          min: 0,
          max: 1,
          inRange: {
            color: ['transparent', '#ff6c02']
          }
        },
        series: [{
          type: 'map',
          map: "china",
          zoom: 1.24,
          selectedMode: false,
          // silent: true,
          label: {
            show: true,
            color: "#FFF",
            fontSize: 12
          },
          itemStyle: {
            borderColor: '#fdcaab', //边界线颜色
            borderWidth: 1, //边际线大小
            borderType: 'solid',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 13,
              color: '#fff', //鼠标滑过区域颜色
            },
            itemStyle: {
              areaColor: "#91ac44"
            },
          },
          data: defaultData,
          markPoint: {
            symbol: 'image://https://qdzstatic01.hsz88.com/static/index/location-ico.png',
            symbolSize: [22, 36],
            zoom: 2,
            label: {
              show: true,
              fontSize: 13,
              color: 'transparent',
            },

            data: [this.openAreasArr[this.curIndex]],
          }
        }]
      };
      this.mapChart.clear();
      this.mapChart.setOption(this.option);
      this.mapChart.on('mouseover', (params) => {
        if (params.name != this.areaName) {
          this.mapChart.dispatchAction({
            type: 'downplay',//
            name: params.name
          });
        }

      })

      this.mapChart.on('click', throttle((params) => {

        const curIndex = this.openAreasArr.findIndex((item) => item.name == params.name)
        // 头部展示 - 当前场馆
        if (curIndex > -1) {
          let clickCurAreasInfo = this.openAreasArr[curIndex];
          this.option.series[0].markPoint.data[0] = clickCurAreasInfo;
          this.mapChart.clear();
          this.mapChart.setOption(this.option);

          this.curSiteInfo(clickCurAreasInfo);
          // 地图高亮 - 展示第一个开放的场馆
          this.highlight(clickCurAreasInfo.name)
          if (this.timer != null) {
            clearInterval(this.timer)
          };
          //
          if (this.timeOuter) {
            clearTimeout(this.timeOuter)
          }
          this.timeOuter = setTimeout(() => {
            // 开始展示指定地方馆信息
            try {
              this.mapChart.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                name: clickCurAreasInfo.name
              });

              // 恢复到点击前位置
              this.option.series[0].markPoint.data[0] = this.curAreasInfo;

              this.mapChart.clear();
              this.mapChart.setOption(this.option);

              // 地图高亮 - 展示第一个开放的场馆
              this.highlight(this.curAreasInfo.name)

              this.circulateSiteInfo()

            } catch (error) {
              console.log(error)
            }

          }, 20000);

        }
      }, 2000));

      // 开始展示指定地方馆信息
      this.circulateSiteInfo()
    },

    // 开始展示指定地方馆信息
    circulateSiteInfo () {
      // 当前开放场馆数量
      let len = this.openAreasArr.length;
      if (len == 0) {
        return
      };

      // 当前场馆信息
      let curAreasInfo = this.openAreasArr[this.curIndex];
      this.curAreasInfo = curAreasInfo
      // 头部展示 - 当前场馆
      this.curSiteInfo(curAreasInfo);

      // 地图高亮 - 展示第一个开放的场馆
      this.highlight(curAreasInfo.name);

      // 最后一条数据的索引
      let lastIndex = len - 1;

      if (this.timer != null) {
        clearInterval(this.timer)
      };

      // 启动定时器，更换展示已开放的场馆
      this.timer = setInterval(() => {
        // 是否已经循环到了最后一条数据
        if (this.curIndex >= lastIndex) {
          this.curIndex = 0
        } else {
          this.curIndex++
        };

        let curAreasInfo = this.openAreasArr[this.curIndex];
        this.curAreasInfo = curAreasInfo
        // 更新气泡位置
        this.option.series[0].markPoint.data[0] = curAreasInfo;
        // console.log(this.option)
        this.mapChart.clear();
        this.mapChart.setOption(this.option);

        // 头部展示 - 当前场馆
        this.curSiteInfo(curAreasInfo);

        // 地图高亮 - 展示第一个开放的场馆
        this.highlight(curAreasInfo.name)
      }, 15000);
    },

    // 头部展示当前高亮场馆信息
    curSiteInfo (obj) {
      // console.log(obj)
      this.$emit('getCurSiteInfo', obj)
    },

    // 高亮展示某个区域
    highlight (name) {
      let dom = null;
      this.areaName = name
      dom = this.mapChart;

      // 高亮之前取消所有高亮

      dom.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        // dataIndex: this.curIndex
        name: name
      });
      // 区域背景颜色高亮
      dom.dispatchAction({
        type: 'highlight',
        // type: 'mapToggleSelect',
        name: name
      })

    },

    // 查看地方馆详情
    goLocalSiteDetail (id, type) {
      // this.$uniAPI.navigateTo('/page_localSite/pages/provinceDetail?id=' + id + '&areaType=' + type)
    },

    // 页面跳转
    skipUrl (url) {
      // this.$uniAPI.navigateTo(url)
    }
  },
  destroyed () {
    clearInterval(this.timer)
  }

}
</script>


<style lang="less" scoped>
.charts-box {
  width: 650px;
  height: 470px;
  margin: 0 auto;
}

.qiun-charts {
  width: 600px;
  height: 470px;
  margin: 0 auto auto;
  position: relative;

  #mapChart {
    width: 600px;
    height: 470px;
  }
}
</style>
