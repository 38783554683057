<template>
  <div class="container">
    <div class="bannber">
      <!-- 地图介绍板块 -->
      <div class="local-pavilion-introduce">
        <div class="local-heard">
          <div class="pavilion-title HeiJian">{{ VenueInfo.venueName }}</div>
          <div class="pavilion-follow" v-if="!isNaN(Number(VenueInfo.followCount) + Number(VenueInfo.realFollowCount))">
            {{ Number(VenueInfo.followCount) + Number(VenueInfo.realFollowCount) }}人关注</div>
        </div>
        <div class="local-tab">
          <div class="local-tab-item" :class="{ active: curIndex == 0 }" @click="curIndex = 0">场馆首页</div>
          <div class="local-tab-item" :class="{ active: curIndex == 1 }" @click="getContent(1)">文化风俗</div>
          <div class="local-tab-item" :class="{ active: curIndex == 2 }" @click="getContent(2)">生态旅游</div>
        </div>

        <div class="local-content scroll">
          <!-- 推荐商品  -->
          <div class="re-good-list" v-show="curIndex === 0">
            <div class="re-good-item" v-for="(item) in resultList" :key="item.goodsId"
              @click="$router.push('/goods?goodsId=' + item.goodsId)">
              <img class="re-good-pic" v-lazy="item.pictureList">
              <div class="re-good-name ellipsis">{{ item.title }}</div>
              <div class="re-good-price">&yen;{{ item.price }}</div>

            </div>
          </div>
          <!-- 文化风俗 -->
          <div class="rich-text" v-show="curIndex === 1" v-html="culturalContent">

          </div>
          <!-- 生态旅游 -->
          <div class="rich-text" v-show="curIndex === 2" v-html="travelContent">
          </div>
        </div>

      </div>
      <div class="local-pavilion-map">
        <LocalMap @getCurSiteInfo="getCurSiteInfo" />
      </div>
    </div>
    <LocalVenue />

  </div>
</template>
<script >
import LocalVenue from './components/local-venue.vue'
import LocalMap from './components/localMap.vue'
import { getVenueById, getGoodsListByVenueId, getContentByVenueId } from '@/api/localPavilion'

import { onMounted, reactive, watch, toRefs, ref } from 'vue'
export default {
  components: {
    LocalVenue,
    LocalMap,
  },
  name: 'localPavilion',
  setup (props) {
    const curIndex = ref(0)
    const state = reactive({
      pageNo: 1,
      list: [],
      totalCount: 1,
      totalPage: 1,
      pageSize: 10,
      currentPage: 1,
      refresh: true,
      VenueInfo: {},
      resultList: [],
      culturalContent: '',
      travelContent: '',
    })

    const getCurSiteInfo = (info) => {
      // console.log('info', info)
      // 获取高亮场馆信息 及商品列表
      curIndex.value = 0
      getVenueInfo({
        id: info.id
      })
      handleGetResultList({
        venueId: info.id,
        pageNo: 1,
        pageSize: 4,
      })
    }
    // 省场馆信息
    const getVenueInfo = (data) => {
      getVenueById(data).then(res => {
        // console.log(res)
        state.VenueInfo = res.data
      })
    }
    // 商品列表
    const handleGetResultList = (data) => {
      getGoodsListByVenueId(data).then(res => {
        // console.log('333', res)
        if (res.code === '10000') {
          state.resultList = res.data.list
        }
      })
    }
    // 1-获取风俗 、 2-旅游信息
    const getContent = (type) => {
      if (curIndex.value === type) return
      curIndex.value = type
      // if (state.culturalContent && state.travelContent) return
      getContentByVenueId(
        {
          contentType: type,
          venueId: state.VenueInfo.id
        }
      ).then(res => {
        // console.log(res)
        // 去除富文本原有样式
        if (type === 1) state.culturalContent = res.data.content.replace(/style="[^=>]*"/g, () => '')
        if (type === 2) state.travelContent = res.data.content.replace(/style="[^=>]*"/g, () => '')
      })
    }

    return {
      ...toRefs(state),
      curIndex,
      getCurSiteInfo,
      getContent
    }
  },
}

</script>
<style scoped lang="less">
.bannber {
  width: 1240px;
  height: 500px;
  margin-bottom: 60px;
  background-image: url(~@/assets/images/local/banner.png);
  background-size: 100%;
  background-position: center;
  padding-top: 15px;
  display: flex;
}

.local-pavilion-introduce {
  width: 246px;
  height: 472px;
  background: rgba(112, 65, 35, 0.34);
  box-shadow: inset 0px 4px 24px 1px rgba(250, 235, 213, 0.37);
  border-radius: 20px;
  overflow: hidden;
  padding: 15px 12px;
  margin: 0 93px 0 246px;

  .local-heard {
    padding-bottom: 6px;
    border-bottom: 1px dashed #e9dac6;
    margin-bottom: 15px;

    .pavilion-title {
      padding: 5px 10px;
      width: fit-content;
      min-width: 86px;
      min-height: 40px;
      margin: 0 auto 6px;
      background: #e9dac6;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      font-size: 22px;
      font-weight: 400;
      color: #5c2f19;
    }

    .pavilion-follow {
      width: fit-content;
      margin: 0 auto;
      font-weight: 400;
      color: #e9dac6;
      font-size: 12px;
    }
  }

  .local-tab {
    display: flex;
    margin-bottom: 15px;

    .local-tab-item {
      width: 66px;
      height: 25px;
      border-radius: 4px 4px 4px 4px;
      background-color: rgba(233, 218, 198, 0.5);
      border: 1px solid #965c35;
      margin-right: 13px;
      font-size: 12px;
      font-weight: 400;
      color: #5c2f19;
      text-align: center;
      line-height: 25px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: rgba(233, 218, 198, 1);
      }
    }
  }

  .re-good-list {
    display: flex;
    flex-wrap: wrap;

    .re-good-item {
      width: 107px;
      margin-bottom: 10px;

      &:nth-child(odd) {
        margin-right: 6px;
      }

      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      .re-good-pic {
        width: 107px;
        height: 107px;
        background: #e9dac6;
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
        margin-bottom: 10px;
      }

      .re-good-name {
        width: 107px;
        font-size: 12px;
        font-weight: 600;
        color: #e9dac6;
        margin-bottom: 5px;
      }

      .re-good-price {
        font-size: 12px;
        font-weight: 600;
        color: #ff6c02;
      }
    }
  }
}

.local-pavilion-map {
  width: 600px;
  height: 470px;
  // background-color: #758993;
}

.local-content {
  height: 322px;
  overflow-y: scroll;
}

.rich-text {
  font-size: 12px;
  font-weight: 500;
  color: #e9dac6;
  word-wrap: break-word;

  p,
  span {
    color: #e9dac6 !important;
  }
}
</style>